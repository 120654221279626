<template>
	<div id="forget-password">
		<div class="rl-notice">
            <span class="red">註：</span>
            <span>此賬號為普通用戶（顧客）賬號，與關聯APP“大醫”互通  如欲<a href="https://os.ectcm.com/Login" class="label-link">登入</a>或<router-link to="/os/doctor_signup" class="label-link">註冊</router-link>中醫在綫管理系統</span>
        </div>
        <div class="rl-container">
            <div class="forget-pwd-form">
                <div class="form-title">找回密码</div>
                <div class="form-item">
                    <input type="text" @input="onPhoneNumberChange" id="fg-username" placeholder="輸入您的註冊手機號碼" v-model="phone">
                </div>
                <div class="form-item">
                    <input type="number" id="fg-phone-code" placeholder="輸入短信驗證碼" v-model="numcode">
                    <div @click="getVCode" :class="{'get-phone-code': true, 'disable': !isPhoneNumber}">{{timer >= 0 ? `${timer}秒後可重發` : '獲取驗證碼'}}</div>
                </div>
                <div class="form-item">
                    <input type="password" id="fg-password" placeholder="輸入新的賬號密碼" v-model="newPwd">
                </div>
                <div class="form-item">
                    <input type="password" id="fg-confirm-password" placeholder="確認賬號密碼" v-model="confirmPwd">
                </div>
                <div class="btn-forget-pwd" @click="resetPassword"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '請稍候...' : '確認'}}</div>
                <router-link to="./login" class="btn-back">&lt;返回登錄註冊</router-link>
            </div>
        </div>
        <div class="btn-home" @click="toHome">
            <span class="icon-back"></span>
            <span>返回首頁</span>
        </div>
	</div>
</template>

<script>
	export default {
		name: "forget-password",
		components: {
        },
        data(){
            return {
                codeKey: '',
                phone: '',
                numcode: '',
                newPwd: '',
                relCode: '',
                confirmPwd: '',
                loading: false,
                isPhoneNumber: false,
                timer: -1,
            }
        },
        methods: {
            toHome: function() {
                window.location.href = "/"
            },
            getVCode: function(){
                if(this.timer > 0) return
                if(/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
                    this.$store.dispatch('getVCode', {codeType: 2, phoneNumber: this.phone}).then((rs) => {
                        this.codeKey = rs.codeKey
                        this.relCode = rs.phoneCode
                        this._toast.success(this, '驗證碼已經發送')
                        this.timer = 90
                        var interval = setInterval(() => {
                            if(this.timer == 0) {
                               interval && clearInterval(interval)
                            }
                            this.timer --
                        }, 1000);
                    }).catch((msg) => {
                        this.timer = -1
                        this._toast.warning(this, msg || '操作失敗，請稍候重試')
                    })
                } else {
                    this._toast.warning(this, '手機號輸入不正確')
                }
            },
            onPhoneNumberChange: function(){
                if(/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
                    this.isPhoneNumber = true
                } else {
                    this.isPhoneNumber = false
                }
            },
            resetPassword: function(){
                if(this.loading) return

                if(! this.phone.trim()) {
                    this._toast.warning(this, '手機號碼不能為空')
                    return
                }
                if(! /^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) && ! /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
                    this._toast.warning(this, '手機號格式不正確')
                    return
                }
                if(! this.numcode) {
                    this._toast.warning(this, '請輸入短信驗證碼')
                    return
                }
                if(!this.newPwd) {
                    this._toast.warning(this, '請輸入新密碼')
                    return
                }
                if(this.newPwd.trim().length < 6) {
                    this._toast.warning(this, '密碼至少為6位字母或数字')
                    return
                }
                if(!this.confirmPwd) {
                    this._toast.warning(this, '請再次輸入新密碼')
                    return
                }
                if(this.newPwd != this.confirmPwd) {
                    this._toast.warning(this, '兩個密碼輸入不一致')
                    return
                }
                if(this.relCode && this.numcode != this.relCode) {
                    this._toast.warning(this, '短信驗證碼不正確')
                    return
                }
                this.loading = true
                var params = {
                    codeKey: this.codeKey,
                    phone: this.phone,
                    numcode: this.numcode,
                    newPwd: this.newPwd,
                }

                this.$store.dispatch('resetPassword', params)
                .then(() => {
                    this._toast.success(this, '密碼已重置，請重新登錄', 5)
                    setTimeout(() => {
                        this.loading = false
                        this.$router.push('/login')
                    }, 500);
                }).catch((rs) => {
                    rs && rs.msg && this._toast.warning(this, rs.msg)
                    this.loading = false
                })
            }
        }
	};
</script>

<style lang="scss" scoped>
   .loading-box {
        background: transparent;
        width: 50%;
        position: absolute;
        left: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -100px;
    }

    #forget-password {
        background-image: url(../../assets/imgs/pc/bg_login@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #forget-password * {
        user-select: none;
    }

    #forget-password .btn-home {
        position: absolute;
        right: 50px;
        top: 30px;
        border: 1px solid #36C4D0;
        border-radius: 10px;
        height: 50px;
        padding: 0px 23px;
        font-size: 24px;
        font-weight: bold;
        color: #36C4D0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    #forget-password .btn-home:active {
        opacity: 0.7;
    }

    #forget-password .btn-home > .icon-back {
        width: 34px;
        height: 30px;
        margin-right: 13px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/ic_back@2x.png);
    }

    #forget-password .rl-notice {
        position: absolute;
        left: 0px;
        bottom: 24px;
        margin-left: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        display: flex;
        flex-direction: row;
    }

    #forget-password .rl-notice > .red {
        color: #EE1C24;
    }

    #forget-password .rl-notice .label-link {
        color: #FF8000;
        text-decoration: underline;
    }

    #forget-password .title-bar {
        display: unset;
        height: auto;
        line-height: unset;
        padding-left: 0px;
        font-weight: normal;
        font-size: unset;
        color: unset;
        background-color: transparent;
    }

    #forget-password .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #fff;
        cursor: pointer;
    }

    #forget-password .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../../assets/imgs/pc/img_cha@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }


    #forget-password > .rl-container {
        width: 50%;
        height: 100%;
        margin-left: 50%;
        display: flex;
        flex-direction: column;
    }

    #forget-password > .rl-container > .rl-content {
        width: 200%;
        height: 455px;
        position: absolute;
        left: 0px;
        top: 0px;
        transition: all .2s linear;
    }

    #forget-password .rl-content .tab-content {
        display: flex;
        flex-direction: column;
        width: 390px;
        height: 455px;
        float: left;
        position: relative;
    }

    #forget-password .rl-content .tab-content.forget-pwd {
        position: absolute;
        left: 0px;
        bottom: -585px;
        height: 585px;
        transition: all .2s linear;
    }

    #forget-password .rl-container .rl-tabs {
        width: 172px;
        height: 30px;
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    #forget-password .rl-container .rl-tabs .rl-tab {
        font-size: 20px;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
        padding: 0px 13px 10px 13px;
        color: #231F20;
        cursor: pointer;
        position: relative;
        white-space: nowrap;
    }

    #forget-password .rl-container .rl-tabs .rl-tab.current {
        color: #36C4D0;
    }

    #forget-password .rl-container .rl-tabs .rl-tab::after {
        position: absolute;
        content: '';
        bottom: -6px;
        width: 20px;
        height: 4px;
        background-color: transparent;
        left: 50%;
        transform: translateX(-50%);
    }

    #forget-password .rl-container .rl-tabs .rl-tab.current::after {
        background-color: #36C4D0;
    }


    #forget-password .rl-container .rl-tabs .divider {
        width: 1px;
        height: 16px;
        margin-top: 2px;
        margin-left: 19px;
        margin-right: 19px;
        background-color: #DDDDDD;
    }

    #forget-password .forget-pwd-form {
        position: relative;
    }
    
    #forget-password .forget-pwd-form > .form-item {
        height: 48px;
        width: 60%;
        max-width: 540px;
        padding-left: 50px;
        margin-top: 10px;
        line-height: 48px;
        border-bottom: 1px solid #E8E8E8;
        box-sizing: border-box;
        position: relative;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(1) {
        margin-top: 0px;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(3) {
        position: relative;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(3) > .get-phone-code {
        position: absolute;
        right: 0;
        height: 28px;
        line-height: 26px;
        box-sizing: border-box;
        border: 1px solid #FF7F00;
        top: 10px;
        padding: 0px 9px;
        font-size: 16px;
        color: #FF7F00;
        font-weight: 400;
        border-radius: 14px;
        cursor: pointer;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(3) > .get-phone-code.disable {
        color: #959595;
        border-color: #959595;
    }

    #forget-password .forget-pwd-form > .form-title {
        font-size: 34px;
        font-weight: bold;
        line-height: 34px;
        color: #242020;
        height: 49px;
        margin-top: 16%;
        margin-bottom: 11%;
    }

    #forget-password .forget-pwd-form > .form-item:hover {
        border-bottom-color: #7BD5DF;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(1) {
        margin-top: 0px;
    }

    #forget-password .forget-pwd-form > .form-item::before {
        position: absolute;
        content: '';
        left: 16px;
        bottom: 15px;
        width: 18px;
        height: 17px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #forget-password .form.login-form > .form-item:nth-child(1)::before {
        background-image: url(../../assets/imgs/pc/icon_zhanghao@2x.png);
        width: 18px;
        height: 17px;
    }

    #forget-password .form.login-form > .form-item:nth-child(2)::before {
        background-image: url(../../assets/imgs/pc/icon_mima@2x.png);
        width: 16px;
        height: 17px;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(2)::before {
        background-image: url(../../assets/imgs/pc/icon_dianhua@2x.png);
        width: 16px;
        height: 16px;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(3)::before {
        background-image: url(../../assets/imgs/pc/icon_yanzhenma@2x.png);
        width: 16px;
        height: 15px;
    }

    #forget-password .forget-pwd-form > .form-item:nth-child(4)::before,
    #forget-password .forget-pwd-form > .form-item:nth-child(5)::before {
        background-image: url(../../assets/imgs/pc/icon_mima@2x.png);
        width: 16px;
        height: 17px;
        left: 17px;
    }

    #forget-password .forget-pwd-form > .form-item > input {
        display: block;
        width: 100%;
        height: 46px;
        margin-bottom: 2px;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }

    #forget-password .forget-pwd-form > .btn-forget-pwd {
        margin-top: 72px;
        width: 270px;
        height: 84px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        line-height: 4;
        text-align: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/img_anniu@2x.png);
        cursor: pointer;
    }

    #forget-password .forget-pwd-form > .btn-back {
        position: absolute;
        left: 57%;
        top: 0;
        text-decoration: underline;
        font-weight: 400;
        color: #FF8000;
        font-size: 18px;
        transform: translateX(-80%);
        margin-top: 16%;
    }

    #forget-password .rl-content .tab-content > .forget-password {
        position: absolute;
        left: 241px;
        bottom: 145px;
        text-decoration: underline;
        font-weight: 400;
        color: #636363;
        line-height: 14px;
        font-size: 14px;
    }
    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */
</style>
